import React from "react";
import { Box, Typography, Button, Container, Paper } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";

function GoogleMapsSection() {
  const googleMapsSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7893978895963!2d-81.39789019999999!3d28.6360734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e770670026a785%3A0xbe00777d37a9ce98!2sDr.%20Anne%20Rothenberg!5e0!3m2!1sen!2sus!4v1719684770821!5m2!1sen!2sus`;

  return (
    <Box
      sx={{
        py: { xs: 6, md: 8 },
        background:
          "linear-gradient(180deg, rgba(251,241,239,0.3) 0%, rgba(255,255,255,1) 100%)",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: "absolute",
          width: "400px",
          height: "400px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(228,181,162,0.1) 0%, rgba(255,255,255,0) 70%)",
          top: "10%",
          right: "-100px",
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            mb: 4,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1.75rem", md: "2.25rem" },
              fontWeight: 700,
              mb: 2,
              position: "relative",
              display: "inline-block",
            }}
          >
            Visit Our Office
            <Box
              sx={{
                position: "absolute",
                bottom: -6,
                left: "25%",
                width: "50%",
                height: "3px",
                background:
                  "linear-gradient(90deg, rgba(228,181,162,0) 0%, #E4B5A2 50%, rgba(228,181,162,0) 100%)",
                borderRadius: "3px",
              }}
            />
          </Typography>

          <Typography
            variant="body1"
            sx={{
              maxWidth: "650px",
              mb: { xs: 3, md: 4 },
              color: "#666",
            }}
          >
            Located in Maitland, FL, our office provides a comfortable and
            private environment where you can speak openly and confidentially.
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            borderRadius: "24px",
            overflow: "hidden",
            boxShadow: "0 10px 40px rgba(0,0,0,0.08)",
            position: "relative",
            height: { xs: "300px", sm: "400px", md: "500px" },
            border: "1px solid rgba(228,181,162,0.3)",
          }}
        >
          <Box
            component="iframe"
            title="Dr. Anne Rothenberg's Office Location"
            src={googleMapsSrc}
            width="100%"
            height="100%"
            frameBorder="0"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            sx={{
              border: 0,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              zIndex: 10,
            }}
          >
            <Button
              variant="contained"
              startIcon={<DirectionsIcon />}
              href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
              target="_blank"
              rel="noreferrer"
              sx={{
                backgroundColor: "white",
                color: "#333",
                fontWeight: 600,
                boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#F8E2DD",
                },
              }}
            >
              Get Directions
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default GoogleMapsSection;
