import React from "react";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const Nav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ position: "relative" }}>
      {isMobile ? <MobileNav /> : <DesktopNav />}
    </Box>
  );
};

export default Nav;
