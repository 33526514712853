import React from "react";
import { Box, Typography, Link, Grid, Container, Divider } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import logo from "./assets/logo_light.png";
import { Link as RouterLink } from "react-router-dom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

const Footer = () => {
  const quickLinks = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "Rates", path: "/rates" },
    { name: "Insurance", path: "/insurance" },
    { name: "Contact", path: "/contact" },
  ];

  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#F8E2DD",
        pt: { xs: 8, md: 10 },
        pb: { xs: 6, md: 8 },
      }}
    >
      {/* Decorative elements */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "6px",
          background: "linear-gradient(90deg, #E4B5A2, #FFB58A)",
          opacity: 0.6,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "500px",
          height: "500px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%)",
          top: "-250px",
          right: "-100px",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "400px",
          height: "400px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%)",
          bottom: "-200px",
          left: "-150px",
        }}
      />

      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/* Logo and About Section */}
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              <Box
                component="img"
                src={logo}
                alt="Dr. Anne Rothenberg Logo"
                sx={{
                  height: "45px",
                  width: "auto",
                  mb: 3,
                  display: "block",
                  mx: { xs: "auto", md: 0 },
                }}
              />

              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1rem",
                    mb: 1.5,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Dr. Anne Rothenberg
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#555",
                    mb: 0.5,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Ph.D., LMHC 14391
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#555",
                    mb: 0.5,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Licensed Mental Health Counselor
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "#555",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Board Certified Clinical Sexologist
                </Typography>
              </Box>

              <Link
                href="https://www.facebook.com/profile.php?id=100064161485273"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
                sx={{
                  display: "block",
                  width: "fit-content",
                  mx: { xs: "auto", md: 0 },
                  color: "#E4B5A2",
                  "&:hover": {
                    color: "#FFB58A",
                  },
                }}
              >
                <FacebookIcon sx={{ fontSize: 24 }} />
              </Link>
            </Box>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 700,
                  mb: 3,
                  position: "relative",
                  display: "inline-block",
                }}
              >
                Quick Links
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -6,
                    left: { xs: "calc(50% - 15px)", md: 0 },
                    width: "30px",
                    height: "2px",
                    backgroundColor: "#E4B5A2",
                  }}
                />
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" },
                  gap: 2,
                }}
              >
                {quickLinks.map((link, index) => (
                  <RouterLink
                    key={index}
                    to={link.path}
                    style={{
                      textDecoration: "none",
                      display: "block",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#555",
                        fontSize: "0.95rem",
                        transition: "all 0.2s ease",
                        textAlign: { xs: "center", md: "left" },
                        "&:hover": {
                          color: "#000",
                          transform: "scale(1.05)",
                        },
                      }}
                    >
                      {link.name}
                    </Typography>
                  </RouterLink>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Contact Details Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: 700,
                  mb: 3,
                  position: "relative",
                  display: "inline-block",
                }}
              >
                Contact Details
                <Box
                  sx={{
                    position: "absolute",
                    bottom: -6,
                    left: { xs: "calc(50% - 15px)", md: 0 },
                    width: "30px",
                    height: "2px",
                    backgroundColor: "#E4B5A2",
                  }}
                />
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" },
                  gap: 2.5,
                }}
              >
                <Link
                  href="tel:4077975469"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    color: "#555",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#000",
                    },
                  }}
                >
                  <PhoneOutlinedIcon sx={{ color: "#E4B5A2", fontSize: 20 }} />
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    407-797-5469
                  </Typography>
                </Link>

                <Link
                  href="mailto:rothenbergcounseling@gmail.com"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    color: "#555",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#000",
                    },
                  }}
                >
                  <EmailOutlinedIcon sx={{ color: "#E4B5A2", fontSize: 20 }} />
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    rothenbergcounseling@gmail.com
                  </Typography>
                </Link>

                <Link
                  href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
                  target="_blank"
                  rel="noreferrer"
                  sx={{
                    display: "flex",
                    alignItems: { xs: "center", md: "flex-start" },
                    gap: 1.5,
                    color: "#555",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#000",
                    },
                  }}
                >
                  <LocationOnOutlinedIcon
                    sx={{
                      color: "#E4B5A2",
                      fontSize: 20,
                      mt: { xs: 0, md: 0.5 },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    2700 Westhall Ln Ste 203,
                    <br />
                    Maitland, FL 32751
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider
          sx={{
            my: { xs: 4, md: 6 },
            borderColor: "rgba(228, 181, 162, 0.3)",
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "#777",
              fontSize: "0.75rem",
            }}
          >
            © {year} Dr. Anne Rothenberg. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
