import React from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  useMediaQuery,
  alpha,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrAnneNoBackground from "./assets/drannenobackground.png"; // Ensure the correct path
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

const Welcome = () => {
  const isIpadPro = useMediaQuery("(min-width:1000px) and (max-width:1366px)");

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 12, sm: 14, md: 16, lg: 20 }, // Increased bottom padding
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          background:
            "linear-gradient(160deg, rgba(255,255,255,0) 0%, rgba(251,241,239,0.3) 50%, rgba(251,241,239,0.5) 100%)",
          zIndex: 0,
        }}
      />

      {/* Bottom fade transition to next section */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "35%",
          background:
            "linear-gradient(0deg, rgba(251,241,239,0.9) 0%, rgba(251,241,239,0) 100%)",
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          right: { xs: "-30%", md: "-15%" },
          bottom: { xs: "5%", md: "15%" },
          width: { xs: "500px", md: "600px" },
          height: { xs: "500px", md: "600px" },
          borderRadius: "50%",
          backgroundColor: alpha("#FFB58A", 0.03),
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          left: { xs: "-20%", md: "-5%" },
          top: { xs: "10%", md: "15%" },
          width: { xs: "300px", md: "400px" },
          height: { xs: "300px", md: "400px" },
          borderRadius: "50%",
          backgroundColor: alpha("#E4B5A2", 0.05),
          zIndex: 0,
        }}
      />

      <Container
        maxWidth="xl"
        sx={{
          position: "relative",
          zIndex: 1,
          px: { xs: 2, sm: 3, md: 4, lg: 6 }, // Added responsive horizontal padding
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            gap: { xs: 6, md: 4, lg: 8 },
          }}
        >
          {/* Text Content Column */}
          <Box
            sx={{
              width: { xs: "100%", md: "55%", lg: "50%" }, // Adjusted for iPad Pro
              textAlign: { xs: "center", md: "left" },
              order: { xs: 2, md: 1 },
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: "-60px", md: "-70px" },
                  left: { xs: "50%", md: "-20px" },
                  transform: { xs: "translateX(-50%)", md: "translateX(0)" },
                  width: { xs: "150px", md: "180px" },
                  height: { xs: "150px", md: "180px" },
                  borderRadius: "50%",
                  backgroundColor: alpha("#FFB58A", 0.1),
                  zIndex: -1,
                }}
              />

              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "12vw",
                    sm: "3.5rem",
                    md: "4rem",
                    lg: "5rem",
                  },
                  fontWeight: 800,
                  lineHeight: 1.1,
                  background: "linear-gradient(90deg, #222 30%, #555 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  mb: { xs: 1, md: 0 },
                  position: "relative",
                }}
              >
                Board Certified
              </Typography>

              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "12vw",
                    sm: "3.5rem",
                    md: "4rem",
                    lg: "5rem",
                  },
                  fontWeight: 800,
                  lineHeight: 1.1,
                  background: "linear-gradient(90deg, #333 30%, #666 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  position: "relative",
                }}
              >
                Clinical Sexologist
                <Box
                  sx={{
                    position: "absolute",
                    bottom: { xs: -8, md: -10 },
                    left: { xs: "25%", md: 0 },
                    width: { xs: "50%", md: "40%" },
                    height: "8px",
                    background:
                      "linear-gradient(90deg, #E4B5A2 0%, rgba(228,181,162,0) 100%)",
                    borderRadius: "4px",
                  }}
                />
              </Typography>
            </Box>

            <Typography
              variant="subtitle1"
              sx={{
                mt: 4,
                mb: 5,
                fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
                color: "#555",
                maxWidth: { xs: "100%", md: "90%" },
                mx: { xs: "auto", md: 0 },
                lineHeight: 1.6,
              }}
            >
              Dr. Rothenberg is dedicated to addressing sexual dysfunction with
              expertise and sensitivity. Embrace a fulfilling and healthy sexual
              life.
            </Typography>

            {/* Call to action buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                flexWrap: { sm: "wrap", md: "nowrap" },
                gap: { xs: 2, sm: 2, md: isIpadPro ? 1.5 : 2 }, // Adjusted gap for iPad Pro
                justifyContent: { xs: "center", md: "flex-start" },
                mb: { xs: 4, md: 0 }, // Added bottom margin on mobile
              }}
            >
              <Button
                component={Link}
                to="/request-consultation"
                variant="contained"
                startIcon={<ForumOutlinedIcon />}
                sx={{
                  backgroundColor: "#E4B5A2",
                  color: "#333",
                  fontSize: {
                    xs: "0.9rem",
                    md: isIpadPro ? "0.85rem" : "1rem",
                  }, // Smaller on iPad Pro
                  fontWeight: 600,
                  py: { xs: 1.5, md: isIpadPro ? 1.25 : 1.5 }, // Adjusted for iPad Pro
                  px: { xs: 3, md: isIpadPro ? 2 : 3 }, // Adjusted for iPad Pro
                  borderRadius: "8px",
                  textTransform: "none",
                  boxShadow: "0 4px 20px rgba(228, 181, 162, 0.25)",
                  minWidth: { xs: "100%", sm: "auto" },
                  whiteSpace: isIpadPro ? "nowrap" : "normal", // Prevent text wrapping on iPad Pro
                  "&:hover": {
                    backgroundColor: "#FFB58A",
                    boxShadow: "0 6px 25px rgba(228, 181, 162, 0.4)",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Request a Consultation
              </Button>

              <Button
                component="a"
                href="tel:407-797-5469"
                variant="outlined"
                startIcon={<PhoneOutlinedIcon />}
                sx={{
                  borderColor: alpha("#E4B5A2", 0.8),
                  borderWidth: 2,
                  color: "#444",
                  fontSize: {
                    xs: "0.9rem",
                    md: isIpadPro ? "0.85rem" : "1rem",
                  }, // Smaller on iPad Pro
                  fontWeight: 600,
                  py: { xs: 1.4, md: isIpadPro ? 1.15 : 1.4 }, // Adjusted for iPad Pro
                  px: { xs: 3, md: isIpadPro ? 2 : 3 }, // Adjusted for iPad Pro
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: { xs: "100%", sm: "auto" },
                  whiteSpace: isIpadPro ? "nowrap" : "normal", // Prevent text wrapping on iPad Pro
                  "&:hover": {
                    borderColor: "#FFB58A",
                    backgroundColor: alpha("#FFB58A", 0.05),
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Call me Directly
              </Button>

              <Button
                component="a"
                href="sms:407-797-5469?body=Hello%20Dr.%20Anne%20Rothenberg,%20I%27d%20like%20to%20inquire%20about..."
                variant="outlined"
                startIcon={<MessageOutlinedIcon />}
                sx={{
                  borderColor: alpha("#E4B5A2", 0.8),
                  borderWidth: 2,
                  color: "#444",
                  fontSize: {
                    xs: "0.9rem",
                    md: isIpadPro ? "0.85rem" : "1rem",
                  }, // Smaller on iPad Pro
                  fontWeight: 600,
                  py: { xs: 1.4, md: isIpadPro ? 1.15 : 1.4 }, // Adjusted for iPad Pro
                  px: { xs: 3, md: isIpadPro ? 2 : 3 }, // Adjusted for iPad Pro
                  borderRadius: "8px",
                  textTransform: "none",
                  minWidth: { xs: "100%", sm: "auto" },
                  whiteSpace: isIpadPro ? "nowrap" : "normal", // Prevent text wrapping on iPad Pro
                  "&:hover": {
                    borderColor: "#FFB58A",
                    backgroundColor: alpha("#FFB58A", 0.05),
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Text me Directly
              </Button>
            </Box>
          </Box>

          {/* Image Column */}
          <Box
            sx={{
              width: { xs: "100%", md: "45%", lg: "50%" }, // Adjusted for iPad Pro
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              order: { xs: 1, md: 2 },
              position: "relative",
              mt: { xs: 2, md: 0 }, // Added top margin on mobile
              mb: { xs: 2, md: 0 }, // Added bottom margin on mobile
            }}
          >
            {/* Decorative rings */}
            <Box
              sx={{
                position: "absolute",
                width: { xs: 320, sm: 360, md: isIpadPro ? 340 : 420 }, // Adjusted for iPad Pro
                height: { xs: 320, sm: 360, md: isIpadPro ? 340 : 420 }, // Adjusted for iPad Pro
                borderRadius: "50%",
                border: "2px dashed rgba(228, 181, 162, 0.3)",
                animation: "spin 60s linear infinite",
                "@keyframes spin": {
                  "0%": { transform: "rotate(0deg)" },
                  "100%": { transform: "rotate(360deg)" },
                },
              }}
            />

            <Box
              sx={{
                position: "absolute",
                width: { xs: 360, sm: 400, md: isIpadPro ? 380 : 460 }, // Adjusted for iPad Pro
                height: { xs: 360, sm: 400, md: isIpadPro ? 380 : 460 }, // Adjusted for iPad Pro
                borderRadius: "50%",
                border: "1px solid rgba(228, 181, 162, 0.2)",
                animation: "spin-reverse 80s linear infinite",
                "@keyframes spin-reverse": {
                  "0%": { transform: "rotate(360deg)" },
                  "100%": { transform: "rotate(0deg)" },
                },
              }}
            />

            {/* Main image with background */}
            <Box
              sx={{
                position: "relative",
                width: { xs: 280, sm: 320, md: isIpadPro ? 300 : 380 }, // Adjusted for iPad Pro
                height: { xs: 280, sm: 320, md: isIpadPro ? 300 : 380 }, // Adjusted for iPad Pro
              }}
            >
              {/* Background gradient circle */}
              <Box
                sx={{
                  position: "absolute",
                  width: "90%",
                  height: "90%",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(135deg, #FFB58A 0%, #E4B5A2 100%)",
                  top: "5%",
                  left: "5%",
                  zIndex: 0,
                  boxShadow: "0 10px 30px rgba(228, 181, 162, 0.3)",
                }}
              />

              {/* Circle border */}
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  border: "3px solid rgba(255, 255, 255, 0.8)",
                  boxSizing: "border-box",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                }}
              />

              {/* Image */}
              <Box
                component="img"
                src={DrAnneNoBackground}
                alt="Dr. Rothenberg"
                sx={{
                  width: "80%",
                  height: "auto",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  zIndex: 1,
                  filter: "drop-shadow(0 5px 15px rgba(0,0,0,0.1))",
                }}
              />

              {/* Accent dots */}
              {[...Array(5)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "absolute",
                    width: { xs: "6px", md: "8px" },
                    height: { xs: "6px", md: "8px" },
                    borderRadius: "50%",
                    backgroundColor: "#FFB58A",
                    opacity: 0.8,
                    top: `${10 + index * 20}%`,
                    right: "-20px",
                    zIndex: 3,
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Welcome;
