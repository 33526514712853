import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// FAQ items data
const faqItems = [
  {
    question: "What types of issues do sex therapists address?",
    answer:
      "Sex therapists are trained professionals who help with a wide array of sexual concerns, including sexual dysfunction, sexual pleasure, sexual orientation, gender identity, and sexual health across various life stages. They may work in individual, couple, or group therapy settings.",
  },
  {
    question: "How do I get started?",
    answer:
      "Getting started is easy! Simply contact Dr. Rothenberg directly to schedule a free initial 15 minute consultation. This session will help Dr. Rothenberg understand your needs and discuss how she can assist you.",
  },
  {
    question: "Does Dr. Rothenberg offer virtual counseling sessions?",
    answer:
      "Yes, Dr. Rothenberg provides virtual counseling options to accommodate clients who prefer online sessions or cannot visit the office in person.",
  },
  {
    question: "What insurance plans does Dr. Rothenberg accept?",
    answer:
      "Dr. Rothenberg is in-network with Aetna, Cigna, Oscar, Oxford, and United Health Care insurance plans.",
  },
  {
    question: "Are virtual sessions covered by insurance?",
    answer:
      "Typically, insurance plans cover virtual sessions in the same way as in-person sessions. However, it is recommended to verify coverage with your insurance provider.",
  },
];

function FAQSection() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        position: "relative",
        overflow: "hidden",
        background:
          "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(251,241,239,0.3) 100%)",
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: "absolute",
          left: { xs: "-30%", md: "-15%" },
          top: "10%",
          width: "400px",
          height: "400px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(228,181,162,0.1) 0%, rgba(255,255,255,0) 70%)",
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          right: { xs: "-30%", md: "-10%" },
          bottom: "5%",
          width: "500px",
          height: "500px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255,181,138,0.1) 0%, rgba(255,255,255,0) 70%)",
          zIndex: 0,
        }}
      />

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Grid container spacing={4}>
          {/* Left side - Title and Description */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              mb: { xs: 2, md: 0 },
              textAlign: { xs: "center", md: "left" }, // Center on mobile, left on desktop
            }}
          >
            <Box
              sx={{
                position: { xs: "static", md: "sticky" },
                top: { md: 100 },
                padding: { xs: 2, md: 4 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2rem", md: "2.5rem" },
                  fontWeight: 700,
                  mb: 2,
                }}
              >
                Frequently Asked Questions
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#666",
                  fontSize: { xs: "1rem", md: "1.125rem" },
                }}
              >
                Got Questions? We've Got Answers!
              </Typography>
            </Box>
          </Grid>

          {/* Right side - Accordion */}
          <Grid item xs={12} md={8}>
            <Box>
              {faqItems.map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  elevation={0}
                  disableGutters
                  sx={{
                    mb: 2,
                    border: "1px solid",
                    borderColor:
                      expanded === `panel${index}`
                        ? "rgba(228, 181, 162, 0.5)"
                        : "rgba(0, 0, 0, 0.06)",
                    borderRadius: "12px !important",
                    overflow: "hidden",
                    transition: "all 0.3s ease",
                    "&::before": {
                      display: "none", // Remove the default divider
                    },
                    "&:hover": {
                      borderColor: "rgba(228, 181, 162, 0.5)",
                      boxShadow:
                        expanded === `panel${index}`
                          ? "0 6px 20px rgba(228, 181, 162, 0.15)"
                          : "none",
                    },
                    backgroundColor:
                      expanded === `panel${index}`
                        ? "rgba(251, 241, 239, 0.7)"
                        : "white",
                    boxShadow:
                      expanded === `panel${index}`
                        ? "0 6px 20px rgba(228, 181, 162, 0.15)"
                        : "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel${index}` ? (
                        <RemoveIcon sx={{ color: "#E4B5A2" }} />
                      ) : (
                        <AddIcon sx={{ color: "#666" }} />
                      )
                    }
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                    sx={{
                      padding: { xs: "12px 16px", md: "20px" }, // Less padding on mobile
                      "&:hover": {
                        backgroundColor: "rgba(251, 241, 239, 0.5)",
                      },
                      "& .MuiAccordionSummary-content": {
                        margin: "12px 0", // Fixed height to prevent title from moving
                        justifyContent: { xs: "flex-start", md: "flex-start" }, // Left-align on mobile
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "1rem", md: "1.125rem" },
                        fontWeight: 500, // Consistent weight whether open or closed
                        color:
                          expanded === `panel${index}` ? "#E4B5A2" : "#333",
                        transition: "color 0.3s ease", // Only transition the color, not other properties
                        textAlign: "left", // Always left-align the text
                      }}
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{
                      padding: { xs: "0 16px 16px", md: "0 24px 24px" }, // Less padding on mobile
                      borderTop: "1px solid rgba(228, 181, 162, 0.2)",
                      textAlign: "left", // Left-align the answer text
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "0.95rem", md: "1rem" },
                        color: "#555",
                        lineHeight: 1.6,
                        mt: 2,
                      }}
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default FAQSection;
