import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Container,
} from "@mui/material";
import { services } from "./Services/servicesDataHome"; // Adjust the path as necessary
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

function ServiceGrid() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine the number of cards to display based on screen size
  const displayedServices = isMobile
    ? services.slice(0, 4)
    : services.slice(0, 6);

  return (
    <Box
      sx={{
        position: "relative",
        py: { xs: 8, md: 10, lg: 12 },
        overflow: "hidden",
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: "absolute",
          width: "500px",
          height: "500px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(251,241,239,0.8) 0%, rgba(255,255,255,0) 70%)",
          top: "-250px",
          right: "-100px",
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "600px",
          height: "600px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(251,241,239,0.6) 0%, rgba(255,255,255,0) 70%)",
          bottom: "-300px",
          left: "-200px",
          zIndex: 0,
        }}
      />

      <Container maxWidth="xl" sx={{ position: "relative", zIndex: 1 }}>
        {/* Section Header */}
        <Box sx={{ mb: { xs: 6, md: 8 }, textAlign: "center" }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "2.25rem", md: "3rem" },
              fontWeight: 700,
              position: "relative",
              display: "inline-block",
              mb: 3,
            }}
          >
            My Services
            <Box
              sx={{
                position: "absolute",
                bottom: -8,
                left: "25%",
                width: "50%",
                height: "4px",
                background:
                  "linear-gradient(90deg, rgba(228,181,162,0) 0%, #E4B5A2 50%, rgba(228,181,162,0) 100%)",
                borderRadius: "4px",
              }}
            />
          </Typography>
        </Box>

        {/* Services Grid */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          sx={{ mb: { xs: 5, md: 6 } }}
        >
          {displayedServices.map((service, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
              }}
            >
              <Card
                component={Link}
                to={`/services/${service.label
                  .toLowerCase()
                  .replace(/ /g, "-")}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderRadius: "16px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "0 8px 20px rgba(0,0,0,0.04)",
                  transition: "all 0.3s ease",
                  background: "#FBF1EF", // Changed from white to match site's warm theme
                  textDecoration: "none",
                  color: "inherit",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 12px 30px rgba(228, 181, 162, 0.2)",
                    "& .service-icon": {
                      transform: "scale(1.05)",
                      backgroundColor: "#FFB58A",
                    },
                    "& .service-arrow": {
                      transform: "translateX(4px)",
                      color: "#E4B5A2",
                    },
                  },
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: "6px",
                    background: "linear-gradient(90deg, #FFB58A, #E4B5A2)",
                    opacity: 0.8,
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: { xs: 3, md: 4 },
                    flex: 1,
                  }}
                >
                  {/* Icon and arrow row */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 3,
                    }}
                  >
                    <Box
                      className="service-icon"
                      sx={{
                        width: { xs: 60, md: 70 },
                        height: { xs: 60, md: 70 },
                        borderRadius: "14px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)", // Lighter background for icon
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 6px 15px rgba(228, 181, 162, 0.15)",
                        transition: "all 0.3s ease",
                      }}
                    >
                      {typeof service.Icon === "string" ? (
                        <Box
                          component="img"
                          src={service.Icon}
                          alt={service.label}
                          sx={{
                            width: { xs: 36, md: 42 },
                            height: "auto",
                            filter: "brightness(0.8)",
                          }}
                        />
                      ) : (
                        <service.Icon
                          sx={{
                            fontSize: { xs: 30, md: 36 },
                            color: "#E4B5A2",
                          }}
                        />
                      )}
                    </Box>

                    <ArrowRightAltIcon
                      className="service-arrow"
                      sx={{
                        fontSize: 24,
                        color: "#999",
                        transition: "all 0.3s ease",
                      }}
                    />
                  </Box>

                  {/* Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "1.125rem", md: "1.25rem" },
                      fontWeight: 700,
                      mb: 2,
                      lineHeight: 1.3,
                    }}
                  >
                    {service.label}
                  </Typography>

                  {/* Description */}
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: "0.9rem", md: "1rem" },
                      color: "#555", // Slightly darker for better contrast on pink
                      lineHeight: 1.6,
                    }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* View All Button */}
        <Box sx={{ textAlign: "center" }}>
          <Button
            component={Link}
            to="/services"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{
              backgroundColor: "#E4B5A2",
              color: "#333",
              fontSize: { xs: "0.9rem", md: "1rem" },
              fontWeight: 600,
              py: 1.5,
              px: 4,
              borderRadius: "8px",
              textTransform: "none",
              boxShadow: "0 4px 15px rgba(228, 181, 162, 0.2)",
              "&:hover": {
                backgroundColor: "#FFB58A",
                boxShadow: "0 6px 20px rgba(228, 181, 162, 0.3)",
                transform: "translateY(-3px)",
              },
              transition: "all 0.3s ease",
            }}
          >
            View all Services
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default ServiceGrid;
