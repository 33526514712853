import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  Button,
  alpha,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Understand from "../common/Understand";
import CustomHelmet from "../common/CustomHelmet";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DateRangeIcon from "@mui/icons-material/DateRange";

function PricingAndInsurance() {
  const navigate = useNavigate();

  const rates = [
    {
      id: 1,
      title: "In Office / Virtual",
      price: "$185",
      icon: <LocationOnIcon sx={{ fontSize: 28, color: "#E4B5A2" }} />,
      duration: "60 Minute Session",
      description:
        "Choose between traditional in-office therapy or the convenience of virtual sessions from the comfort of your home via platforms like FaceTime, Zoom, and others.",
    },
    {
      id: 2,
      title: "After Hours / Intensive / Travel Sessions",
      price: "$300",
      icon: <DateRangeIcon sx={{ fontSize: 28, color: "#E4B5A2" }} />,
      duration: "60 Minute Session",
      description:
        "Available for urgent needs, intensive sessions, or sessions requiring travel. Ideal for those requiring more flexible options.",
    },
  ];

  return (
    <>
      <CustomHelmet
        title="Self-Pay Therapy Rates - Flexible Options Available"
        description="Explore transparent self-pay rates for therapy sessions with Dr. Anne Rothenberg. Choose from in-office or virtual therapy sessions to suit your needs. Flexible options including after-hours and travel sessions available."
        keywords="self-pay therapy rates, virtual therapy costs, in-office therapy pricing, after-hours therapy sessions, travel therapy rates, Dr. Anne Rothenberg therapy options"
        ogTitle="Explore Self-Pay Therapy Rates with Dr. Anne Rothenberg"
        ogDescription="Discover flexible self-pay options for therapy with Dr. Anne Rothenberg, including virtual and in-office sessions. Affordable rates for personalized care."
        ogUrl="https://drannerothenberg.com/rates"
      />

      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          py: { xs: 6, md: 10 },
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            width: "500px",
            height: "500px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(251,241,239,0.8) 0%, rgba(255,255,255,0) 70%)",
            top: "-250px",
            right: "-100px",
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            width: "600px",
            height: "600px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(251,241,239,0.6) 0%, rgba(255,255,255,0) 70%)",
            bottom: "-300px",
            left: "-200px",
            zIndex: 0,
          }}
        />

        <Container
          maxWidth="lg"
          sx={{ position: "relative", zIndex: 1, px: { xs: 2, sm: 3 } }}
        >
          <Box sx={{ textAlign: "center", mb: { xs: 6, md: 8 } }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "2.5rem", sm: "3rem", md: "3.5rem" },
                fontWeight: 800,
                position: "relative",
                display: "inline-block",
                mb: 2,
                background: "linear-gradient(90deg, #222 30%, #555 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Self-Pay Options
              <Box
                sx={{
                  position: "absolute",
                  bottom: -10,
                  left: "25%",
                  width: "50%",
                  height: "6px",
                  background:
                    "linear-gradient(90deg, rgba(228,181,162,0) 0%, #E4B5A2 50%, rgba(228,181,162,0) 100%)",
                  borderRadius: "3px",
                }}
              />
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: "1rem", md: "1.25rem" },
                color: "#666",
                maxWidth: "800px",
                mx: "auto",
                mt: 4,
              }}
            >
              Transparent pricing options designed to fit your needs and
              preferences. Choose the session type that works best for you.
            </Typography>
          </Box>

          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ mb: { xs: 6, md: 8 } }}
          >
            {rates.map((rate) => (
              <Grid item xs={12} md={6} key={rate.id}>
                <Card
                  elevation={0}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: { xs: "20px", md: "24px" },
                    overflow: "hidden",
                    position: "relative",
                    transition: "all 0.3s ease",
                    border: "1px solid rgba(228, 181, 162, 0.3)",
                    "&:hover": {
                      transform: "translateY(-8px)",
                      boxShadow: "0 20px 40px rgba(228, 181, 162, 0.15)",
                      borderColor: "rgba(228, 181, 162, 0.6)",
                    },
                  }}
                >
                  {/* Top decorative gradient */}
                  <Box
                    sx={{
                      height: "6px",
                      background: "linear-gradient(90deg, #E4B5A2, #FFB58A)",
                      opacity: 0.8,
                    }}
                  />

                  <CardContent
                    sx={{
                      p: { xs: 3, md: 4 },
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: { xs: "3rem", md: "3.5rem" },
                        fontWeight: 700,
                        mb: 2,
                        background:
                          "linear-gradient(90deg, #333 0%, #666 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {rate.price}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "1.1rem", md: "1.25rem" },
                          mb: 1,
                        }}
                      >
                        {rate.title}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <AccessTimeIcon
                          sx={{ fontSize: 18, color: "#E4B5A2" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666",
                            fontWeight: 500,
                          }}
                        >
                          {rate.duration}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider sx={{ width: "70%", mb: 3 }} />

                    <Typography
                      variant="body1"
                      sx={{
                        color: "#555",
                        mb: 3,
                        lineHeight: 1.6,
                        fontSize: { xs: "0.95rem", md: "1rem" },
                        maxWidth: "85%",
                      }}
                    >
                      {rate.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Insurance Notice */}
          <Box
            sx={{
              position: "relative",
              borderRadius: "20px",
              overflow: "hidden",
              mb: { xs: 8, md: 10 },
              background:
                "linear-gradient(135deg, rgba(255,252,235,0.7) 0%, rgba(255,248,224,0.5) 100%)",
              border: "1px solid rgba(233, 213, 2, 0.3)",
              boxShadow: "0 10px 30px rgba(233, 213, 2, 0.07)",
              textAlign: "center",
              p: { xs: 3, md: 4 },
            }}
          >
            {/* Decorative circle */}
            <Box
              sx={{
                position: "absolute",
                top: "-50px",
                right: "-50px",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                backgroundColor: alpha("#E9D502", 0.05),
                zIndex: 0,
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "-70px",
                left: "-70px",
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                backgroundColor: alpha("#E9D502", 0.05),
                zIndex: 0,
              }}
            />

            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "1.25rem", md: "1.4rem" },
                  mb: 2,
                  color: "#333",
                }}
              >
                Plan to use insurance?
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#555",
                  mb: 3,
                  maxWidth: "700px",
                  mx: "auto",
                  fontSize: { xs: "1rem", md: "1.1rem" },
                }}
              >
                Dr. Rothenberg accepts select insurance plans. View available
                options and coverage details.
              </Typography>

              <Button
                onClick={() => navigate("/insurance")}
                variant="outlined"
                sx={{
                  borderColor: "#E9D502",
                  color: "#333",
                  fontWeight: 600,
                  py: 1.2,
                  px: 4,
                  borderRadius: "10px",
                  textTransform: "none",
                  fontSize: { xs: "0.95rem", md: "1rem" },
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: alpha("#E9D502", 0.1),
                    borderColor: "#E9D502",
                    transform: "translateY(-3px)",
                  },
                }}
              >
                View my Insurance Options
              </Button>
            </Box>
          </Box>

          <Understand />
        </Container>
      </Box>
    </>
  );
}

export default PricingAndInsurance;
