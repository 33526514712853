import React from "react";
import { Box, Typography, Container, Button, alpha } from "@mui/material";
import { Link } from "react-router-dom";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const ContactSection = () => {
  return (
    <Container maxWidth="lg" sx={{ my: { xs: 5, md: 10 } }}>
      <Box
        sx={{
          position: "relative",
          backgroundImage: "linear-gradient(135deg, #FBF1EF 0%, #FFE6DF 100%)",
          borderRadius: { xs: 3, md: 6 },
          overflow: "hidden",
          boxShadow: "0 20px 80px -20px rgba(228, 181, 162, 0.25)",
        }}
      >
        {/* Top decorative wave */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: { xs: "80px", md: "120px" },
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg viewBox='0 0 1200 120' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cpath d='M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z' fill='%23FFB58A' fill-opacity='.1'/%3E%3Cpath d='M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z' fill='%23E4B5A2' fill-opacity='.1'/%3E%3C/svg%3E\")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 0,
          }}
        />

        {/* Content container */}
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            p: { xs: 3, md: 6 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "1.75rem", sm: "2rem", md: "2.25rem" },
              fontWeight: 700,
              textAlign: "center",
              mb: 2,
              color: "#333",
              textShadow: "0 2px 10px rgba(228, 181, 162, 0.2)",
            }}
          >
            Get in Touch
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "1rem", md: "1.125rem" },
              textAlign: "center",
              color: "#666",
              mb: { xs: 4, md: 6 },
              mx: "auto",
              maxWidth: "650px",
            }}
          >
            Dr. Anne Rothenberg welcomes your questions and is ready to help.
            Your privacy is{" "}
            <Box component="span" sx={{ fontWeight: 700 }}>
              always
            </Box>{" "}
            assured.
          </Typography>

          {/* Creative contact buttons layout */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: 2.5, md: 4 },
            }}
          >
            {/* Consultation Button */}
            <Button
              component={Link}
              to="/request-consultation"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", sm: "300px", md: "220px" },
                height: { xs: "130px", sm: "160px", md: "200px" },
                borderRadius: { xs: 3, md: 4 },
                backgroundColor: alpha("#E4B5A2", 0.9),
                transition: "all 0.3s ease",
                overflow: "hidden",
                "&:hover": {
                  transform: "translateY(-8px)",
                  backgroundColor: "#E4B5A2",
                  boxShadow: "0 15px 30px rgba(228, 181, 162, 0.3)",
                  "& .icon-container": {
                    transform: "translateY(-10px)",
                    "& svg": {
                      transform: "scale(1.1)",
                    },
                  },
                  "& .button-text": {
                    transform: "translateY(-5px)",
                  },
                },
              }}
            >
              <Box
                className="icon-container"
                sx={{
                  transition: "transform 0.3s ease",
                  mb: { xs: 1, md: 2 },
                }}
              >
                <ForumOutlinedIcon
                  sx={{
                    fontSize: { xs: 36, md: 48 },
                    color: "#FFF",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
              <Typography
                className="button-text"
                sx={{
                  color: "#FFF",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  transition: "transform 0.3s ease",
                }}
              >
                Request a Consultation
              </Typography>

              {/* Decorative circles */}
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: { xs: 70, md: 100 },
                  height: { xs: 70, md: 100 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: -30,
                  left: -30,
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                  zIndex: 0,
                }}
              />
            </Button>

            {/* Phone Button */}
            <Button
              component="a"
              href="tel:4077975469"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", sm: "300px", md: "220px" },
                height: { xs: "130px", sm: "160px", md: "200px" },
                borderRadius: { xs: 3, md: 4 },
                backgroundColor: alpha("#FFB58A", 0.9),
                transition: "all 0.3s ease",
                overflow: "hidden",
                "&:hover": {
                  transform: "translateY(-8px)",
                  backgroundColor: "#FFB58A",
                  boxShadow: "0 15px 30px rgba(255, 181, 138, 0.3)",
                  "& .icon-container": {
                    transform: "translateY(-10px)",
                    "& svg": {
                      transform: "scale(1.1)",
                    },
                  },
                  "& .button-text": {
                    transform: "translateY(-5px)",
                  },
                },
              }}
            >
              <Box
                className="icon-container"
                sx={{
                  transition: "transform 0.3s ease",
                  mb: { xs: 1, md: 2 },
                }}
              >
                <PhoneOutlinedIcon
                  sx={{
                    fontSize: { xs: 36, md: 48 },
                    color: "#FFF",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
              <Typography
                className="button-text"
                sx={{
                  color: "#FFF",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  transition: "transform 0.3s ease",
                }}
              >
                407-797-5469
              </Typography>

              {/* Decorative circles */}
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: { xs: 70, md: 100 },
                  height: { xs: 70, md: 100 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: -30,
                  left: -30,
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                  zIndex: 0,
                }}
              />
            </Button>

            {/* Email Button */}
            <Button
              component="a"
              href="mailto:rothenbergcounseling@gmail.com"
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "100%", sm: "300px", md: "220px" },
                height: { xs: "130px", sm: "160px", md: "200px" },
                borderRadius: { xs: 3, md: 4 },
                backgroundColor: alpha("#F5CFC0", 0.9),
                transition: "all 0.3s ease",
                overflow: "hidden",
                "&:hover": {
                  transform: "translateY(-8px)",
                  backgroundColor: "#F5CFC0",
                  boxShadow: "0 15px 30px rgba(245, 207, 192, 0.3)",
                  "& .icon-container": {
                    transform: "translateY(-10px)",
                    "& svg": {
                      transform: "scale(1.1)",
                    },
                  },
                  "& .button-text": {
                    transform: "translateY(-5px)",
                  },
                },
              }}
            >
              <Box
                className="icon-container"
                sx={{
                  transition: "transform 0.3s ease",
                  mb: { xs: 1, md: 2 },
                }}
              >
                <EmailOutlinedIcon
                  sx={{
                    fontSize: { xs: 36, md: 48 },
                    color: "#FFF",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
              <Typography
                className="button-text"
                sx={{
                  color: "#FFF",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", md: "1.125rem" },
                  transition: "transform 0.3s ease",
                }}
              >
                Email
              </Typography>

              {/* Decorative circles */}
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: { xs: 70, md: 100 },
                  height: { xs: 70, md: 100 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: -30,
                  left: -30,
                  width: { xs: 60, md: 80 },
                  height: { xs: 60, md: 80 },
                  borderRadius: "50%",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                  zIndex: 0,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactSection;
