import React from "react";
import { Box, Link, Tooltip } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

function UtilityNav() {
  return (
    <Box
      sx={{
        width: "100%",
        background: "linear-gradient(90deg, #F8E2DD 0%, #F5D7CF 100%)",
        boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
        position: "relative",
        zIndex: 10,
      }}
    >
      {/* Desktop Version */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "7%", // Maintained the original 7% margin
          marginRight: "7%", // Maintained the original 7% margin
          padding: "8px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "translateY(-2px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 28,
              width: 28,
              borderRadius: "50%",
              backgroundColor: "rgba(228, 181, 162, 0.2)",
              mr: 1,
            }}
          >
            <LocationOnOutlinedIcon
              sx={{
                color: "#E4B5A2",
                fontSize: "1.1rem",
              }}
            />
          </Box>
          <Link
            href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
            target="_blank"
            rel="noreferrer"
            sx={{
              textDecoration: "none",
              color: "#555",
              fontSize: "0.85rem",
              "&:hover": {
                color: "#000",
              },
              transition: "color 0.2s ease",
            }}
          >
            2700 Westhall Ln Ste 203, Maitland, FL 32751
          </Link>
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", gap: { md: 3, lg: 4 } }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "translateY(-2px)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 28,
                width: 28,
                borderRadius: "50%",
                backgroundColor: "rgba(228, 181, 162, 0.2)",
                mr: 1,
              }}
            >
              <EmailOutlinedIcon
                sx={{
                  color: "#E4B5A2",
                  fontSize: "1.1rem",
                }}
              />
            </Box>
            <Link
              href="mailto:rothenbergcounseling@gmail.com"
              sx={{
                textDecoration: "none",
                color: "#555",
                fontSize: "0.85rem",
                "&:hover": {
                  color: "#000",
                },
                transition: "color 0.2s ease",
              }}
            >
              rothenbergcounseling@gmail.com
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "translateY(-2px)",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 28,
                width: 28,
                borderRadius: "50%",
                backgroundColor: "rgba(228, 181, 162, 0.2)",
                mr: 1,
              }}
            >
              <PhoneOutlinedIcon
                sx={{
                  color: "#E4B5A2",
                  fontSize: "1.1rem",
                }}
              />
            </Box>
            <Link
              href="tel:+14077975469"
              sx={{
                textDecoration: "none",
                color: "#555",
                fontSize: "0.85rem",
                "&:hover": {
                  color: "#000",
                },
                transition: "color 0.2s ease",
              }}
            >
              407-797-5469
            </Link>
          </Box>
        </Box>
      </Box>

      {/* Mobile Version */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "space-evenly",
          padding: "8px 0",
        }}
      >
        <Tooltip
          title="2700 Westhall Ln Ste 203, Maitland, FL 32751"
          arrow
          placement="bottom"
        >
          <Link
            href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
            target="_blank"
            rel="noreferrer"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 38,
              width: 38,
              borderRadius: "50%",
              backgroundColor: "rgba(228, 181, 162, 0.2)",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgba(228, 181, 162, 0.3)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <LocationOnOutlinedIcon
              sx={{
                color: "#E4B5A2",
                fontSize: "1.25rem",
              }}
            />
          </Link>
        </Tooltip>

        <Tooltip
          title="rothenbergcounseling@gmail.com"
          arrow
          placement="bottom"
        >
          <Link
            href="mailto:rothenbergcounseling@gmail.com"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 38,
              width: 38,
              borderRadius: "50%",
              backgroundColor: "rgba(228, 181, 162, 0.2)",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgba(228, 181, 162, 0.3)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <EmailOutlinedIcon
              sx={{
                color: "#E4B5A2",
                fontSize: "1.25rem",
              }}
            />
          </Link>
        </Tooltip>

        <Tooltip title="407-797-5469" arrow placement="bottom">
          <Link
            href="tel:+14077975469"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 38,
              width: 38,
              borderRadius: "50%",
              backgroundColor: "rgba(228, 181, 162, 0.2)",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgba(228, 181, 162, 0.3)",
                transform: "translateY(-2px)",
              },
            }}
          >
            <PhoneOutlinedIcon
              sx={{
                color: "#E4B5A2",
                fontSize: "1.25rem",
              }}
            />
          </Link>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default UtilityNav;
