import React, { useState } from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Backdrop,
  alpha,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

function ConsultationForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactMethod: "",
    email: "",
    phoneNumber: "",
    preference: "",
    reason: "",
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.firstName || !formData.lastName || !formData.contactMethod) {
      alert("Please make sure all required fields are filled out.");
      return;
    }

    setLoading(true);
    fetch(
      "https://cmam0ede8e.execute-api.us-east-2.amazonaws.com/Test/drAnneConsultation",
      {
        method: "POST",
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        setSubmitted(true);
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  if (submitted) {
    return (
      <Box
        sx={{
          py: { xs: 10, md: 12 },
          px: { xs: 3, md: 4 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "6px",
            background: "linear-gradient(90deg, #E4B5A2, #FFB58A)",
            opacity: 0.6,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            width: "400px",
            height: "400px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(228,181,162,0.1) 0%, rgba(255,255,255,0) 70%)",
            top: "10%",
            right: "-100px",
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            width: "500px",
            height: "500px",
            borderRadius: "50%",
            background:
              "radial-gradient(circle, rgba(255,181,138,0.08) 0%, rgba(255,255,255,0) 70%)",
            bottom: "-250px",
            left: "-150px",
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: "50%",
            width: 80,
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 8px 32px rgba(228, 181, 162, 0.2)",
            mb: 4,
          }}
        >
          <SendIcon
            sx={{
              fontSize: 36,
              color: "#E4B5A2",
              transform: "rotate(20deg)",
            }}
          />
        </Box>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            mb: 2,
            color: "#444",
          }}
        >
          Thank you for taking the first step
        </Typography>

        <Typography
          variant="h6"
          sx={{
            maxWidth: "600px",
            color: "#666",
            fontWeight: 400,
          }}
        >
          Dr. Anne Rothenberg will be in touch within 48 hours. We're excited to
          help you on your journey.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        py: { xs: 8, md: 10 },
        px: { xs: 3, md: 4 },
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: "absolute",
          width: "400px",
          height: "400px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(228,181,162,0.1) 0%, rgba(255,255,255,0) 70%)",
          top: "10%",
          right: "-100px",
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          position: "absolute",
          width: "500px",
          height: "500px",
          borderRadius: "50%",
          background:
            "radial-gradient(circle, rgba(255,181,138,0.08) 0%, rgba(255,255,255,0) 70%)",
          bottom: "-250px",
          left: "-150px",
          zIndex: 0,
        }}
      />

      <Container
        maxWidth="md"
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2rem", md: "2.75rem" },
              fontWeight: 700,
              mb: 2,
              color: "#333",
              position: "relative",
              display: "inline-block",
            }}
          >
            Request a Consultation
            <Box
              sx={{
                position: "absolute",
                bottom: -8,
                left: "25%",
                width: "50%",
                height: "4px",
                background:
                  "linear-gradient(90deg, rgba(228,181,162,0) 0%, #E4B5A2 50%, rgba(228,181,162,0) 100%)",
                borderRadius: "4px",
              }}
            />
          </Typography>

          <Typography
            variant="body1"
            sx={{
              maxWidth: "700px",
              mx: "auto",
              color: "#666",
            }}
          >
            You've already taken the hardest step by deciding to seek support.
            Complete this brief form, and Dr. Rothenberg will personally respond
            within 48 hours to discuss your next steps together.
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "0 10px 40px rgba(0,0,0,0.05)",
            border: "1px solid rgba(228,181,162,0.2)",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            sx={{
              p: { xs: 3, md: 5 },
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
                gap: 3,
              }}
            >
              {/* First Name field */}
              <TextField
                label="First name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
                variant="outlined"
                fullWidth
                InputProps={{
                  sx: {
                    borderRadius: "8px",
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E4B5A2",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E4B5A2",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#E4B5A2",
                    },
                  },
                }}
              />

              {/* Last Name field */}
              <TextField
                label="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
                variant="outlined"
                fullWidth
                InputProps={{
                  sx: {
                    borderRadius: "8px",
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E4B5A2",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E4B5A2",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#E4B5A2",
                    },
                  },
                }}
              />
            </Box>

            {/* Session preference field */}
            <TextField
              label="Virtual or in-person?"
              name="preference"
              select
              value={formData.preference}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  borderRadius: "8px",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E4B5A2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E4B5A2",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#E4B5A2",
                  },
                },
              }}
            >
              <MenuItem value="virtual">Virtual</MenuItem>
              <MenuItem value="inPerson">In-Person</MenuItem>
            </TextField>

            {/* Reason field */}
            <TextField
              label="What are you seeking help with?"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  borderRadius: "8px",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E4B5A2",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E4B5A2",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "#E4B5A2",
                  },
                },
              }}
            />

            {/* Contact preferences */}
            <FormControl
              component="fieldset"
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                p: 3,
                backgroundColor: alpha("#F8E2DD", 0.1),
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  mb: 2,
                  fontWeight: 500,
                  color: "#555 !important",
                }}
              >
                How would you prefer I get back to you?
              </FormLabel>

              <RadioGroup
                row
                name="contactMethod"
                value={formData.contactMethod}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  justifyContent: { xs: "space-around", md: "center" },
                  gap: { md: 5 },
                }}
              >
                <FormControlLabel
                  value="email"
                  control={
                    <Radio
                      sx={{
                        color: "#E4B5A2",
                        "&.Mui-checked": {
                          color: "#E4B5A2",
                        },
                      }}
                    />
                  }
                  label="Email"
                />
                <FormControlLabel
                  value="text"
                  control={
                    <Radio
                      sx={{
                        color: "#E4B5A2",
                        "&.Mui-checked": {
                          color: "#E4B5A2",
                        },
                      }}
                    />
                  }
                  label="Text"
                />
                <FormControlLabel
                  value="call"
                  control={
                    <Radio
                      sx={{
                        color: "#E4B5A2",
                        "&.Mui-checked": {
                          color: "#E4B5A2",
                        },
                      }}
                    />
                  }
                  label="Call"
                />
              </RadioGroup>

              {formData.contactMethod === "email" && (
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E4B5A2",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E4B5A2",
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#E4B5A2",
                      },
                    },
                  }}
                />
              )}

              {(formData.contactMethod === "call" ||
                formData.contactMethod === "text") && (
                <TextField
                  label="Phone number"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E4B5A2",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E4B5A2",
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#E4B5A2",
                      },
                    },
                  }}
                />
              )}
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#E4B5A2",
                  color: "#333",
                  fontWeight: 600,
                  py: 1.5,
                  px: 4,
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: "1rem",
                  boxShadow: "0 4px 15px rgba(228, 181, 162, 0.2)",
                  "&:hover": {
                    backgroundColor: "#FFB58A",
                    boxShadow: "0 6px 20px rgba(228, 181, 162, 0.3)",
                    transform: "translateY(-3px)",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Send Request
              </Button>
            </Box>
          </Box>
        </Paper>

        <Box
          sx={{
            textAlign: "center",
            mt: 4,
            color: "#666",
            fontSize: "0.9rem",
          }}
        >
          <Typography variant="body2">
            Your privacy is important to us. Information shared is kept
            confidential.
          </Typography>
        </Box>
      </Container>

      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              color: "#E4B5A2",
            }}
          />
        </Backdrop>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{
            bgcolor: "#E4B5A2",
            color: "white",
            "& .MuiAlert-icon": {
              color: "white",
            },
          }}
        >
          Your consultation request has been submitted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ConsultationForm;
