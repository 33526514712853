import React from "react";
import { Box, Typography, Container, alpha } from "@mui/material";
import DrAnneImage from "./assets/dranne-hq-updated.jpg"; // Replace with the correct path to the image
import VerifiedIcon from "@mui/icons-material/Verified";
import SchoolIcon from "@mui/icons-material/School";

const ProfileSection = () => {
  return (
    <Container maxWidth="lg" sx={{ my: { xs: 6, md: 10 } }}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          borderRadius: { xs: 3, md: 6 },
          background: "#FFF",
          boxShadow: "0 20px 80px -20px rgba(0,0,0,0.08)",
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: { xs: "60%", md: "40%" },
            height: "100%",
            background:
              "linear-gradient(135deg, rgba(248,226,221,0.4) 0%, rgba(228,181,162,0.05) 100%)",
            clipPath: {
              xs: "polygon(100% 0, 100% 100%, 0 100%, 100% 0)",
              md: "polygon(100% 0, 100% 100%, 25% 100%, 60% 0)",
            },
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            width: 300,
            height: 300,
            borderRadius: "50%",
            backgroundColor: alpha("#FFB58A", 0.03),
            top: -150,
            left: -100,
            zIndex: 0,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            width: 200,
            height: 200,
            borderRadius: "50%",
            backgroundColor: alpha("#E4B5A2", 0.05),
            bottom: -80,
            right: "40%",
            zIndex: 0,
          }}
        />

        {/* Content container */}
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {/* Image column with decorative styling */}
          <Box
            sx={{
              position: "relative",
              width: { xs: "100%", md: "45%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 3, md: 0 },
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: { xs: 280, sm: 320, md: 360, lg: 400 },
                height: { xs: 280, sm: 320, md: 360, lg: 400 },
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
                mx: { xs: "auto", md: 0 },
                my: { xs: 2, md: 6 },
                border: "8px solid white",
              }}
            >
              <Box
                component="img"
                src={DrAnneImage}
                alt="Dr. Anne Rothenberg"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center top",
                }}
              />
            </Box>

            {/* Decorative elements around the image */}
            <Box
              sx={{
                position: "absolute",
                top: { xs: "5%", md: "15%" },
                left: { xs: "5%", md: "15%" },
                width: 60,
                height: 60,
                borderRadius: "50%",
                background: "linear-gradient(135deg, #FFB58A 0%, #E4B5A2 100%)",
                opacity: 0.1,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: { xs: "5%", md: "15%" },
                right: { xs: "5%", md: "5%" },
                width: 80,
                height: 80,
                borderRadius: "50%",
                background: "linear-gradient(135deg, #E4B5A2 0%, #FFB58A 100%)",
                opacity: 0.15,
              }}
            />
          </Box>

          {/* Content column */}
          <Box
            sx={{
              width: { xs: "100%", md: "55%" },
              p: { xs: 3, md: 6 },
              textAlign: "left",
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  fontSize: { xs: "2rem", sm: "2.25rem", md: "2.5rem" },
                  fontWeight: 700,
                  mb: 1,
                  color: "#333",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                Dr. Anne Rothenberg
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    bottom: -5,
                    height: 6,
                    width: "30%",
                    background:
                      "linear-gradient(90deg, #E4B5A2 0%, rgba(228,181,162,0) 100%)",
                    borderRadius: 3,
                  }}
                />
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 0.5,
                }}
              >
                <SchoolIcon sx={{ fontSize: 20, color: "#E4B5A2" }} />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    color: "#555",
                  }}
                >
                  Ph.D., LMHC 14391
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 3,
                }}
              >
                <VerifiedIcon sx={{ fontSize: 20, color: "#E4B5A2" }} />
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "0.875rem", md: "0.95rem" },
                    color: "#666",
                  }}
                >
                  Licensed Mental Health Counselor | Board Certified Clinical
                  Sexologist
                </Typography>
              </Box>
            </Box>

            {/* Bio text with decorative elements */}
            <Box
              sx={{
                position: "relative",
                mb: 4,
                p: 3,
                borderRadius: 3,
                backgroundColor: alpha("#FBF1EF", 0.5),
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "1.1rem", md: "1.25rem" },
                  fontWeight: 600,
                  mb: 2,
                  color: "#444",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                Meet Dr. Anne Rothenberg
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "0.95rem", md: "1rem" },
                  lineHeight: 1.6,
                  color: "#555",
                  mb: 4,
                }}
              >
                Dr. Anne Rothenberg is renowned for her warm, accepting, and
                non-judgmental approach. She creates a safe and welcoming
                environment where you can comfortably discuss sensitive issues.
                Her open and direct communication style ensures that
                individuals, couples, and families feel at ease addressing
                challenges that are often difficult to talk about.
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "1.1rem", md: "1.25rem" },
                  fontWeight: 600,
                  mb: 2,
                  color: "#444",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                Expertise in Human Sexuality
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "0.95rem", md: "1rem" },
                  lineHeight: 1.6,
                  color: "#555",
                }}
              >
                As a Board Certified Clinical Sexologist and a globally
                recognized expert in human sexuality and sexual behavior, Dr.
                Rothenberg offers specialized counseling in Transsexual and
                Transgender issues. She adheres to the highest standards of care
                set by the World Professional Association for Transgender Health
                (WPATH).
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileSection;
