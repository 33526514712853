import React from "react";
import { Box, Grid, Typography, Link, Button } from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import GoogleMaps from "../../components/common/Map";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

const ContactPageTitleTypography = ({
  children,
  icon: IconComponent,
  sx,
  ...props
}) => (
  <Typography
    variant="subtitle1"
    sx={{
      fontSize: { xs: 16, md: 24 },
      fontWeight: 700,
      lineHeight: { xs: "19.2px", md: "28.8px" },
      marginBottom: { xs: "16px", md: "24px" },
      ...sx,
    }}
    {...props}
  >
    {IconComponent && <IconComponent sx={{ verticalAlign: "middle", mr: 1 }} />}
    {children}
  </Typography>
);

function SimpleLayout() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          padding: { xs: "48px 16px", md: "88px 100px" },
        }}
      >
        <Typography variant="h1">Contact</Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mb: { xs: "24px", md: "36px" },
            fontSize: { xs: 16, md: 24 },
            fontWeight: 400,
            lineHeight: "28.8px",
            textAlign: "center",
          }}
        >
          Your privacy is <strong>always</strong> assured.
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            component="a"
            href="sms:407-797-5469?body=Hello%20Dr.%20Anne%20Rothenberg,%20I%27d%20like%20to%20inquire%20about..."
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "#E4B5A2",
              padding: "8px 14px",
              borderRadius: "6px",
              fontSize: { xs: 16, md: 12, lg: 16 },
              textTransform: "none",
              width: { xs: "55%", md: "auto" },
              "&:hover": {
                backgroundColor: "#FFB58A",
                borderColor: "#FFB58A",
                color: "#FFFFFF",
              },
            }}
          >
            <MessageOutlinedIcon sx={{ mr: "4px" }} />
            Text me Directly
          </Button>

          <Button
            component="a"
            href="tel:407-797-5469"
            variant="outlined"
            sx={{
              color: "black",
              borderColor: "#E4B5A2",
              padding: "8px 14px",
              borderRadius: "6px",
              fontSize: { xs: 16, md: 12, lg: 16 },
              textTransform: "none",
              width: { xs: "55%", md: "auto" },
              "&:hover": {
                backgroundColor: "#FFB58A",
                borderColor: "#FFB58A",
                color: "#FFFFFF",
              },
            }}
          >
            <PhoneOutlinedIcon sx={{ mr: "4px" }} />
            Call me Directly
          </Button>
        </Box>
      </Box>

      <Grid container sx={{ padding: { xs: "48px 16px", md: "88px 100px" } }}>
        {/* Left Box: Address */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            mb: { xs: 6 },
            display: { md: "flex" },
            justifyContent: { xs: "flex-start", sm: "center" },
          }}
        >
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            {/* Control the width of the box */}
            <ContactPageTitleTypography icon={MapOutlinedIcon}>
              Address
            </ContactPageTitleTypography>
            <Link
              href="https://maps.app.goo.gl/cWZaSH3NGqFiBY6o9"
              target="_blank"
              rel="noopener"
              underline="none"
              sx={{ color: "inherit", "&:hover": { color: "#c62828" } }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: 16, sm: 18 },
                  fontWeight: 400,
                  lineHeight: "21.6px",
                  color: "#5D5D5D",
                }}
              >
                {" "}
                2700 Westhall Ln Ste 203
                <br></br>
                Maitland, FL 32751
              </Typography>
            </Link>
          </Box>
        </Grid>

        {/* Middle Box: Contact Details */}

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            mb: { xs: 6 },
            justifyContent: { xs: "flex-start", sm: "center" },
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            {/* Control the width of the box */}
            <ContactPageTitleTypography icon={PhoneOutlinedIcon}>
              Contact Details
            </ContactPageTitleTypography>
            <Link
              href="tel:407-797-5469"
              underline="none"
              sx={{ color: "inherit", "&:hover": { color: "#c62828" } }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: 16, sm: 18 },
                  fontWeight: 400,
                  lineHeight: "21.6px",
                  mb: "8px",
                  color: "#5D5D5D",
                }}
              >
                <SmartphoneOutlinedIcon
                  sx={{
                    verticalAlign: "middle",
                    mr: 1,
                    fontSize: "medium",
                    color: "#5D5D5D",
                  }}
                />
                407-797-5469
              </Typography>
            </Link>
            <Link
              href="mailto:rothenbergcounseling@gmail.com"
              underline="none"
              sx={{ color: "inherit", "&:hover": { color: "#c62828" } }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: 16, sm: 18 },
                  fontWeight: 400,
                  lineHeight: "21.6px",
                  color: "#5D5D5D",
                }}
              >
                <EmailOutlinedIcon
                  sx={{ verticalAlign: "middle", mr: 1, fontSize: "medium" }}
                />
                rothenbergcounseling@gmail.com
              </Typography>
            </Link>
          </Box>
        </Grid>

        {/* Right Box: Schedule */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { md: "flex" },
            justifyContent: { xs: "flex-start", sm: "center" },
            mb: { xs: 2 },
          }}
        >
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            <ContactPageTitleTypography icon={ScheduleOutlinedIcon}>
              Schedule
            </ContactPageTitleTypography>
            <Typography
              sx={{ fontSize: { xs: 16, md: "18px" }, fontWeight: 700 }}
            >
              Monday to Friday
            </Typography>
            <Typography
              sx={{
                mb: {
                  xs: "4px",
                  md: "8px",
                  fontSize: { xs: 16, md: "18px" },
                  color: "#5D5D5D",
                },
              }}
            >
              9:00am – 5:00pm
            </Typography>
            <Typography
              sx={{ fontSize: { xs: 16, md: "18px" }, fontWeight: 700 }}
            >
              Saturday
            </Typography>
            <Typography
              sx={{
                mb: {
                  xs: "4px",
                  md: "8px",
                  fontSize: { xs: 16, md: "18px" },
                  color: "#5D5D5D",
                },
              }}
            >
              9:00am – 2:00pm
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 16, md: "18px" },
                fontWeight: 700,
                mb: "4px",
              }}
            >
              {" "}
              Sunday
            </Typography>
            <Typography
              sx={{ color: "#c62828", fontSize: { xs: 16, md: "18px" } }}
            >
              Closed
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <GoogleMaps />
    </Box>
  );
}

export default SimpleLayout;
