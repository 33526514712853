import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Typography,
  Button,
  useScrollTrigger,
  Slide,
  Fade,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoneyOutlined";
import ContactMailIcon from "@mui/icons-material/ContactMailOutlined";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ShieldIcon from "@mui/icons-material/Shield";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo_light.png";

// Hide navigation on scroll down
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const MobileNav = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const isActive = (path) => {
    if (path === "/" && location.pathname === "/") return true;
    if (path !== "/" && location.pathname.startsWith(path)) return true;
    return false;
  };

  const navItems = [
    { text: "Home", path: "/", icon: <HomeIcon /> },
    { text: "Services", path: "/services", icon: <Diversity3Icon /> },
    { text: "Rates", path: "/rates", icon: <AttachMoneyIcon /> },
    { text: "Insurance", path: "/insurance", icon: <ShieldIcon /> },
    { text: "Contact", path: "/contact", icon: <ContactMailIcon /> },
  ];

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        elevation={scrolled ? 2 : 0}
        sx={{
          backgroundColor: scrolled ? "rgba(255, 255, 255, 0.98)" : "#fff",
          transition: "all 0.3s ease",
          borderBottom: scrolled ? "none" : "1px solid rgba(0, 0, 0, 0.06)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 1.5,
          }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Dr. Anne Rothenberg Logo"
              sx={{
                height: { xs: "35px", sm: "40px" },
                width: "auto",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            />
          </Link>

          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: "rgba(228, 181, 162, 0.1)",
              borderRadius: "10px",
              padding: "8px",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(228, 181, 162, 0.2)",
                transform: "rotate(90deg)",
              },
            }}
          >
            <MenuIcon sx={{ color: "#333" }} />
          </IconButton>

          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                width: { xs: "100%", sm: 320 },
                borderTopLeftRadius: { xs: 0, sm: "16px" },
                borderBottomLeftRadius: { xs: 0, sm: "16px" },
                background: "linear-gradient(135deg, #FFF 0%, #FBF1EF 100%)",
                boxShadow: "-5px 0 25px rgba(0, 0, 0, 0.1)",
              },
            }}
            SlideProps={{
              appear: true,
              direction: "left",
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid rgba(228, 181, 162, 0.3)",
              }}
            >
              <Box
                component="img"
                src={logo}
                alt="Dr. Anne Rothenberg Logo"
                sx={{ height: "40px", width: "auto" }}
              />
              <IconButton
                onClick={toggleDrawer(false)}
                sx={{
                  backgroundColor: "rgba(228, 181, 162, 0.1)",
                  "&:hover": {
                    backgroundColor: "rgba(228, 181, 162, 0.2)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <List sx={{ pt: 2, px: 2 }}>
              {navItems.map((item, index) => (
                <Fade
                  in={drawerOpen}
                  key={item.text}
                  style={{
                    transitionDelay: drawerOpen ? `${index * 50}ms` : "0ms",
                  }}
                >
                  <ListItem
                    button
                    component={Link}
                    to={item.path}
                    onClick={toggleDrawer(false)}
                    sx={{
                      my: 0.8,
                      borderRadius: "12px",
                      backgroundColor: isActive(item.path)
                        ? "rgba(228, 181, 162, 0.15)"
                        : "transparent",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        backgroundColor: "rgba(228, 181, 162, 0.25)",
                        transform: "translateX(5px)",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: isActive(item.path) ? "#E4B5A2" : "#666",
                        minWidth: "40px",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontWeight: isActive(item.path) ? 600 : 400,
                        fontSize: "1.1rem",
                      }}
                    />
                  </ListItem>
                </Fade>
              ))}

              <Fade
                in={drawerOpen}
                style={{
                  transitionDelay: drawerOpen
                    ? `${navItems.length * 50 + 100}ms`
                    : "0ms",
                }}
              >
                <Box sx={{ px: 1, pt: 4, pb: 2 }}>
                  <Button
                    component={Link}
                    to="/request-consultation"
                    fullWidth
                    startIcon={<ForumOutlinedIcon />}
                    onClick={toggleDrawer(false)}
                    sx={{
                      backgroundColor: "#E4B5A2",
                      color: "#333",
                      py: 1.5,
                      borderRadius: 3,
                      textTransform: "none",
                      fontSize: "1rem",
                      fontWeight: 600,
                      boxShadow: "0 4px 12px rgba(228, 181, 162, 0.25)",
                      "&:hover": {
                        backgroundColor: "#FFB58A",
                        transform: "translateY(-3px)",
                        boxShadow: "0 8px 16px rgba(228, 181, 162, 0.3)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    Request a Consultation
                  </Button>
                </Box>
              </Fade>

              <Fade
                in={drawerOpen}
                style={{
                  transitionDelay: drawerOpen
                    ? `${navItems.length * 50 + 200}ms`
                    : "0ms",
                }}
              >
                <Box
                  sx={{
                    mt: 4,
                    mx: 2,
                    p: 2.5,
                    borderRadius: 3,
                    backgroundColor: "rgba(228, 181, 162, 0.1)",
                    border: "1px solid rgba(228, 181, 162, 0.2)",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontStyle: "italic",
                      color: "#666",
                      fontSize: "0.9rem",
                      textAlign: "center",
                    }}
                  >
                    Your privacy and comfort are my top priorities during our
                    sessions.
                  </Typography>
                </Box>
              </Fade>
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default MobileNav;
