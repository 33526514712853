import React from "react";
import { Toolbar, Typography, Button, Box, Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo_light.png";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

const DesktopNav = () => {
  const location = useLocation();

  // Check if path matches current location
  const isActive = (path) => {
    if (path === "/" && location.pathname === "/") return true;
    if (path !== "/" && location.pathname.startsWith(path)) return true;
    return false;
  };

  // Navigation items
  const navItems = [
    { label: "Home", path: "/" },
    { label: "Services", path: "/services" },
    { label: "Rates", path: "/rates" },
    { label: "Insurance", path: "/insurance" },
    { label: "Contact", path: "/contact" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
        borderBottom: "1px solid rgba(228, 181, 162, 0.1)",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          ml: "7%",
          mr: "7%",
          px: 0,
          width: "auto",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "80px",
            padding: 0,
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-2px)",
              },
            }}
          >
            <Link to="/" style={{ display: "flex", alignItems: "center" }}>
              <img
                src={logo}
                alt="Dr. Anne Rothenberg Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </Link>
          </Box>

          {/* Navigation Links */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { md: 1, lg: 2 },
            }}
          >
            {navItems.map((item) => (
              <Box
                key={item.label}
                sx={{
                  position: "relative",
                  mr: { md: 1.5, lg: 2 },
                }}
              >
                <Typography
                  component={Link}
                  to={item.path}
                  sx={{
                    color: isActive(item.path) ? "#333" : "#666",
                    fontWeight: isActive(item.path) ? 600 : 500,
                    fontSize: { md: "0.9rem", lg: "1rem" },
                    textDecoration: "none",
                    padding: "8px 2px",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      color: "#333",
                    },
                  }}
                >
                  {item.label}
                </Typography>

                {/* Active indicator underline */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: isActive(item.path) ? "100%" : "0%",
                    height: "2px",
                    backgroundColor: "#E4B5A2",
                    transition: "width 0.3s ease",
                    opacity: isActive(item.path) ? 1 : 0,
                  }}
                />

                {/* Hover indicator underline */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "0%",
                    height: "2px",
                    backgroundColor: "#FFB58A",
                    transition: "width 0.3s ease",
                    opacity: 0,
                    "&:hover": {
                      width: "100%",
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            ))}

            {/* Consultation Button */}
            <Button
              component={Link}
              to="/request-consultation"
              variant="contained"
              startIcon={<ForumOutlinedIcon />}
              sx={{
                backgroundColor: "#E4B5A2",
                color: "#333",
                fontSize: { md: "0.85rem", lg: "0.9rem" },
                fontWeight: 600,
                padding: { md: "8px 14px", lg: "10px 18px" },
                borderRadius: "8px",
                textTransform: "none",
                boxShadow: "none",
                transition: "all 0.3s ease",
                ml: { md: 1, lg: 2 },
                "&:hover": {
                  backgroundColor: "#FFB58A",
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 12px rgba(228, 181, 162, 0.2)",
                },
              }}
            >
              Request Consultation
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </Box>
  );
};

export default DesktopNav;
